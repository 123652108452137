@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































.oper {
  padding: 20px 10px;
  height: calc(100vh - 45px);
  overflow-y: scroll;
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
}
.fixed {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
